*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}

.navbar {
  position: absolute;
  width: 100%;
  background-color: transparent;
  color: #000000;
  z-index: 100;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
}

.menu-items {
  order: 2;
  display: none;
}
.logo {
  order: 1;
}

.menu-items li {
  list-style: none;
  font-size: 16px;
}

.navbar a {
  color: #444;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.navbar a:hover {
  color: #117964;
}

@media (max-width: 1280px) {
  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .navbar-container {
    display: block;
    position: relative;
    /* height: 100%; */
  }

  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-container .hamburger-lines {
    display: block;
    height: 20px;
    width: 25px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #333;
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar .menu-items {
    padding-top: 75px;
    background: #fff;
    height: 200vh;
    max-width: 300px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 40px;
    transition: transform 0.5s ease-in-out;
    box-shadow: 5px 0px 10px 0px #aaa;
  }

  .navbar .menu-items li {
    font-weight: 500;
    font-size: 14px;
  }

  .logo {
    position: absolute;
    top: 5px;
    left: 50px;
  }
  .auth-button {
    position: absolute;
    top: 10px;
    right: 0px;
  }

  .navbar-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
  .navbar-container input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
}

@media (max-width: 620px) {
  .navbar-container input[type="checkbox"]:checked ~ .logo,
  .navbar-container input[type="checkbox"]:checked ~ .auth-button .sign {
    display: none;
  }
  .navbar-container input[type="checkbox"]:checked ~ .auth-button .join,
  .navbar-container input[type="checkbox"]:checked ~ .auth-button .profile {
    display: block;
  }
}

@media (max-width: 360px) {
  .navbar-container .auth-button .sign {
    display: none;
  }
}
